import type { ContentCarouselModule } from './modules/content-carousel'
import { ProductSource } from '~/components/providers/ProductProvider/ProductProvider'
import type { HeroContentModule } from './modules/hero'
import type { LegacyContentModule } from './modules/legacy'
import type { StoryPlayerModule } from './modules/storyplayer'

export enum ContentSource {
	COREMEDIA = 'coremedia',
	MCP = 'mcp',
}

export type ContentSourceType = (typeof ContentSource)[keyof typeof ContentSource]

// This is a list of the sources that are allowed to trigger interaction events.
export const allowedInteractionSources: string[] = [ContentSource.MCP, ProductSource.MCP]

export const enum ContentModuleType {
	CAROUSEL = 'carousel',
	HERO = 'hero',
	LEGACY = 'legacy',
	STORYPLAYER = 'storyplayer',
}

/**
 * This is a list of the types of content that we explicitly support in the
 * content module system.  This list will be used to validate the type of
 * the content module.
 */
export type SupportedContentTypes = Lowercase<keyof typeof ContentModuleType>

export type ProjectedContentModuleType = (typeof ContentModuleType)[keyof typeof ContentModuleType]

export const HeroViewTypesList = [
	'hero-plp',
	'hero-overlay',
	'dt-category-hero',
	'hero01-mini',
	'hero-mini',
	'hero-overlay-transparent',
	'hero-split',
	'hero-split-dark',
] as const

export const LegacyViewTypesList = [
	'dt-general-banner',
	'dt-general-banner-text-box',
	'category-banner',
	'dt-split-banner',
	'dt-split-banner-black',
	'hero-3section-4',
	'hero-singleimage-snipe-3',
	'dt-storytelling-split',
	'dt-storytelling-organic',
	'storytelling-full-image',
	'storytelling-split-image',
	'storytelling-split-1',
	'storytelling-split-2',
	'storytelling-organic-1',
	'storytelling-organic-2',
	'storytelling-quote-2',
	'dt-textBox',
	'storytelling-quote-1',
	'storytelling-text-1',
	'storytelling-text-2',
	'dt-storytelling-3up',
	'dt-bucket-3up',
	'dt-bucket-4up',
	'dt-bucket-3column',
	'dt-bucket-4column',
	'dt-bucket-router',
	'dt-bucket-router-wide',
	'dt-bucket-scattered',
	'dt-horizontal-scroll',
	'single-in-grid-seo',
	'single-in-grid',
	'dt-in-grid-double',
	'dt-in-grid-single',
	'dt-in-grid-triple-split',
	'dt-in-grid-triple',
	'triple-in-grid-3',
	'triple-in-grid-1',
	'triple-in-grid-seo',
	'double-in-grid-seo-1',
	'signup',
	'signup-short',
	'seo-grid-linking',
	'cm-article',
	'accordion-collection',
	'enrollment',
] as const

export type HeroViewTypes = (typeof HeroViewTypesList)[number]

export type LegacyViewTypes = (typeof LegacyViewTypesList)[number]

export type ContentModule = LegacyContentModule | HeroContentModule | ContentCarouselModule | StoryPlayerModule

/**
 * This is the type of the transformer function.  Note that it takes a generic
 * type 'unknown' as the input.  This is because we don't know what the input
 * data will look like.  It is up to the transformer to validate the input data
 * and return a valid ContentModule object.  If the input is invalid, the
 * transformer should return undefined.
 */
export type ContentSourceTransformer = (data: unknown, locale?: string) => ContentModule | undefined

/**
 * This is the type of the identifier function.  It takes a generic type 'unknown'
 * as the input.  This is because we don't know what the input data will look like.
 * It is up to the identifier to validate the input data and return a boolean value
 * indicating whether or not the input data is valid for the transformer.
 */
export type ContentSourceIdentifier = (data: unknown) => boolean

/**
 * This is the type of the registry that will be used to register the transformer
 * functions.  It is an array of objects that contain the type of the transformer
 * and the transformer function itself.
 */
export type ContentSourceTransformerRegistry = {
	type: SupportedContentTypes
	identifier: ContentSourceIdentifier
	transformer: ContentSourceTransformer
}[]
