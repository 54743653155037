import { useCallback, useState, useEffect, useMemo } from 'react'
import type {
	McpCampaignResponse,
	McpHeroCampaignResponse,
	McpOnEventResponse,
	McpPreviewMessageEventData,
	McpProductRecsCampaignResponse,
	McpSitewideBannerCampaignResponse,
} from '~/lib/types/mcp.interface'
import {
	McpPreviewMessageTypes,
	McpPreviewMessageServices,
	McpPreviewMessageServiceMethods,
	McpCampaignTypes,
} from '~/lib/client-only/mcp/mcp.constants'
import {
	getEvergageCampaignIndex,
	getEvergageCampaign,
	addEvergageCustomEventListener,
	removeEvergageCustomEventListener,
	EvergageEvent,
} from '~/lib/client-only/mcp/evergage'
import { createClientLogger } from '~/lib/logger'
import { ContentSource } from '~/lib/client-server/cms/types'

const logger = createClientLogger('mcp')

const LOCAL_STORAGE_KEY = 'MCP_CAMPAIGN_RESPONSE'

/**
 * Stores a value in the local storage.
 *
 * @param key - The key under which the value is stored.
 * @param value - The value to be stored.
 * @template T
 */
function setLocalStorageItem<T>(key: string, value: T) {
	window.localStorage.setItem(key, JSON.stringify(value))
}

/**
 * Retrieves a value from the local storage.
 *
 * @param key - The key under which the value is stored.
 * @returns The retrieved value, or null if an error occurred or the key does not exist.
 */
function getLocalStorageItem(key: string) {
	const item = window.localStorage.getItem(key)
	try {
		return item ? JSON.parse(item) : null
	} catch (error) {
		logger.error(error, `Error parsing localStorage item at key "${key}":`)
		return null
	}
}

/**
 * Custom react hook for managing MCP related operations.
 * This hook handles the loading of Evergage, fetching of campaign responses,
 * listening to Evergage events, and provides utility functions to get specific campaign data.
 *
 * @returns An object containing:
 * - campaigns: An array of campaign responses.
 * - evergageLoaded: A boolean indicating if Evergage has loaded.
 * - getPlacementData: A function to get the placement data of the HERO campaign.
 * - getProductRecs: A function to get the product recommendations of the PRODUCT_RECS campaign.
 */
export default function useMcp() {
	const [evergageLoaded, setEvergageLoaded] = useState(false)
	const [campaigns, setCampaigns] = useState<McpCampaignResponse[]>([])

	const evergageOnEventResponseHandler = useCallback((domEvent: CustomEvent<McpOnEventResponse>) => {
		const campaignResponses = domEvent.detail?.response?.campaignResponses
		const pageView = domEvent.detail?.actionEvent?.pageView
		// Note: we are storing the campaign responses only on the first page view and campaign responses are not empty.
		if (campaignResponses && pageView) {
			setLocalStorageItem(LOCAL_STORAGE_KEY, campaignResponses)
			setCampaigns(campaignResponses)
		}
	}, [])

	const evergageWindowOnMessageHandler = useCallback(
		(event: MessageEvent<McpPreviewMessageEventData>) => {
			if (
				event.origin === window.origin && // Only listen when visual editor turned on same origin
				event.data.type === McpPreviewMessageTypes.CALL_IN_PAGE_SERVICES_METHOD &&
				event.data?.payload?.serviceName === McpPreviewMessageServices.SITE_EDITOR_FRAME_SERVICE &&
				event.data.payload.serviceMethod === McpPreviewMessageServiceMethods.RENDER_TEMPLATE &&
				Array.isArray(event.data.payload.functionArguments) &&
				event.data.payload.functionArguments.length >= 2
			) {
				const [_, functionArguments] = event.data.payload.functionArguments
				const foundCampaignPayloadIndex = getEvergageCampaignIndex(McpCampaignTypes.HERO, campaigns)

				if (foundCampaignPayloadIndex === -1 || !functionArguments) {
					console.warn(`Cannot update payload for campaign, didn't find campaign id for hero`)
					return
				}

				const oldCampaign = campaigns[foundCampaignPayloadIndex]
				const newCampaigns = [...campaigns]
				// Remove the found campaign from the list of campaigns
				newCampaigns.splice(foundCampaignPayloadIndex, 1)
				setCampaigns([
					...newCampaigns,
					{
						...oldCampaign,
						payload: functionArguments,
					},
				])
			}
		},
		[campaigns],
	)

	// Note: check periodically if evergage window object is loaded.
	// Once loaded, add event listener for OnEventResponse event. This event is triggered on every event sent to MCP which has updated campaign data.
	useEffect(() => {
		const interval = setInterval(() => {
			if (window?.Evergage) {
				setEvergageLoaded(true)
				addEvergageCustomEventListener(EvergageEvent.OnEventResponse, evergageOnEventResponseHandler)
				clearInterval(interval)
			}
		}, 10)

		return () => {
			if (window?.Evergage) {
				removeEvergageCustomEventListener(EvergageEvent.OnEventResponse, evergageOnEventResponseHandler)
			}
		}
	}, [evergageOnEventResponseHandler])

	// Note: This is a workaround to listen to messages from the Salesforce chrome extension visual editor
	useEffect(() => {
		window.addEventListener('message', evergageWindowOnMessageHandler)
		return () => {
			window.removeEventListener('message', evergageWindowOnMessageHandler)
		}
	}, [evergageWindowOnMessageHandler])

	// Note: This is a workaround to get the campaign responses from the local storage when evergage is loaded and the visual editor is turned on
	useEffect(() => {
		if (evergageLoaded && window?.self !== window.top) {
			const cachedCampaignResponses = getLocalStorageItem(LOCAL_STORAGE_KEY)
			if (cachedCampaignResponses) {
				setCampaigns(cachedCampaignResponses)
			}
		}
	}, [evergageLoaded])

	const getPlacementData = useCallback(
		(): { placementData: McpHeroCampaignResponse | undefined; source: ContentSource } => ({
			placementData: getEvergageCampaign(McpCampaignTypes.HERO, campaigns),
			source: ContentSource.MCP,
		}),
		[campaigns],
	)

	const getProductRecs = useCallback(
		(): McpProductRecsCampaignResponse | undefined => getEvergageCampaign(McpCampaignTypes.PRODUCT_RECS, campaigns),
		[campaigns],
	)

	const getSitewideBannerData = useCallback(
		(): McpSitewideBannerCampaignResponse | undefined =>
			getEvergageCampaign(McpCampaignTypes.SITEWIDE_BANNER, campaigns),
		[campaigns],
	)

	return useMemo(
		() => ({
			campaigns,
			evergageLoaded,
			getPlacementData,
			getProductRecs,
			getSitewideBannerData,
		}),
		[campaigns, evergageLoaded, getPlacementData, getProductRecs, getSitewideBannerData],
	)
}
