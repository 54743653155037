// MCP Datasets
export enum McpDataSets {
	STAGING = 'ua_staging_sku',
	PRODUCTION = 'ua_prod_sku',
}

export enum McpCampaignTypes {
	HERO = 'HERO',
	PRODUCT_RECS = 'PRODUCT_RECS',
	SITEWIDE_BANNER = 'SITEWIDE_BANNER',
}

export enum McpImagesTypes {
	DESKTOP,
	MOBILE,
}

// These templates must match in the MCP backend by name and be used inside a campaign
// for that campaign to be considered of that type.
export const McpTemplateNames: {
	[key in keyof typeof McpDataSets]?: { [key in keyof typeof McpCampaignTypes]?: string }
} = {
	STAGING: {
		HERO: 'Demo Hero Template',
		PRODUCT_RECS: 'Headless PDP Template Example',
		SITEWIDE_BANNER: 'Sitewide Banner Template',
	},
	PRODUCTION: {
		HERO: 'Demo Hero Template',
		PRODUCT_RECS: 'Headless Product Recommendations',
		SITEWIDE_BANNER: 'Sitewide Banner Template',
	},
}

export enum McpPreviewMessageTypes {
	CALL_IN_PAGE_SERVICES_METHOD = 'CALL_IN_PAGE_SERVICES_METHOD',
}

export enum McpPreviewMessageServiceMethods {
	RENDER_TEMPLATE = 'renderTemplate',
}

export enum McpPreviewMessageServices {
	SITE_EDITOR_FRAME_SERVICE = 'siteEditorFrameService',
}

export enum McpCampaignStatType {
	IMPRESSION = 'Impression',
	CLICKTHROUGH = 'Clickthrough',
	DISMISSAL = 'Dismissal',
}

export const McpCampaignStatUserGroup = {
	TEST: 'Test',
	CONTROL: 'Control',
}
