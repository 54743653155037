'use client'

import React, { createContext } from 'react'
import type { McpContextType } from '~/lib/types/mcp.interface'
import useMcp from '~/components/hooks/personalization/useMcp'

/**
 * PersonalizationContext is a React context that provides personalization data.
 * It has a default value that throws an error if the context is used without a provider.
 * @type {React.Context<McpContextType>}
 */
export const PersonalizationContext = createContext<McpContextType>({
	evergageLoaded: false,
	campaigns: undefined,
	getPlacementData: () => {
		throw new Error(
			'getPlacementData called before it was defined. You must have a PersonalizationProvider in the parent render tree',
		)
	},
	getProductRecs: () => {
		throw new Error(
			'getProductRecs called before it was defined. You must have a PersonalizationProvider in the parent render tree',
		)
	},
	getSitewideBannerData: () => {
		throw new Error(
			'getSitewideBannerData called before it was defined. You must have a PersonalizationProvider in the parent render tree',
		)
	},
})

/**
 * PersonalizationProvider is a React component that provides the PersonalizationContext to its children.
 * It uses the useMcp hook to get the value for the context.
 * @param {React.ReactNode} props.children The children to render inside the provider.
 * @returns {React.ReactElement} A context provider with the personalization data.
 */
export const PersonalizationProvider = ({ children }) => {
	const mcpValues = useMcp()

	return <PersonalizationContext.Provider value={mcpValues}>{children}</PersonalizationContext.Provider>
}
