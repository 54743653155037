/* eslint-disable no-underscore-dangle */
import { createClientLogger } from '~/lib/logger'
import { McpPdpRecsProductsSchema, type McpPdpRecsProductsSafe } from './schema'
import { zodParseHandler } from '~/lib/schemas/utils'

/**
 * Create a logger instance.
 */
const logger = createClientLogger('mcp')

/**
 * Class representing a PdpRecs implementation.
 */
export class PdpRecsImpl {
	/**
	 * @private
	 * @type {McpPdpRecsProductsSafe | undefined}
	 */
	private _parsedData: McpPdpRecsProductsSafe | undefined

	/**
	 * Create a PdpRecsImpl.
	 * @param {unknown} data - The data to be parsed.
	 */
	constructor(data: unknown) {
		try {
			this._parsedData = zodParseHandler(McpPdpRecsProductsSchema, data, {
				message: 'Failed to parse data using McpProductsSchema',
			})
		} catch (e) {
			logger.error(e, 'Failed to parse data using McpProductsSchema')
		}
	}

	/**
	 * Get the raw parsed data.
	 * @return {McpPdpRecsProductsSafe | undefined} The parsed data.
	 */
	get raw() {
		return this._parsedData
	}
}
