import type { RecommendationPayload } from '~/graphql/generated/uacapi/type-document-node'
import type { getProductSuggestions, getProductTilesByProductIds } from '~/lib/products'
import { PRODUCT_TILE_LOADING_IMAGE } from '~/lib/products'
import { createClientLogger } from '~/lib/logger'

const logger = createClientLogger('mcp')

/**
 * Transforms an array of recommendations or a RecommendationPayload into an object with a suggestions property.
 * Each suggestion includes product details.
 *
 * @param recommendations - The recommendations to transform.
 * @returns  An object with a suggestions property. Each suggestion is an object with product details.
 * @see RecommendationPayload
 */
export const getProductTiles = (
	recommendations:
		| Awaited<ReturnType<typeof getProductSuggestions>>['data']['productRecommendations']
		| Awaited<ReturnType<typeof getProductTilesByProductIds>>['data']['productsById']
		| null
		| undefined,
): { suggestions: RecommendationPayload['recommendations'] | [] } => {
	const arrayToMap = Array.isArray(recommendations) ? recommendations : recommendations?.recommendations
	return {
		suggestions:
			arrayToMap
				?.map((args) => {
					if (args && args?.__typename === 'MasterProduct') {
						const images = args?.colors?.length ? args.colors[0]?.assets?.images : null
						const altImage = args?.colors ? args?.colors[0].assets?.alt : null
						return {
							...args,
							id: args?.id,
							name: args?.copy?.name,
							price: { list: args?.prices?.list, sale: args?.prices?.sale },
							exclusiveType: args?.inventory?.exclusiveType,
							orderable: args?.inventory?.orderable,
							preorderable: args?.inventory?.preorderable,
							image: {
								alt: images ? altImage : '',
								url: images ? images[0].url : PRODUCT_TILE_LOADING_IMAGE,
								urlHov: images ? images.slice(0, 2).at(-1)?.url : PRODUCT_TILE_LOADING_IMAGE,
							},
						}
					}
					logger.error(args, `Product tile data is missing or not MasterProduct type.`)
					return null
				})
				.filter((product) => product && product.id !== '') || [],
	}
}
