import { z } from 'zod'

export const McpBaseSchema = z.object({
	backgroundColor: z.enum(['Dark', 'Light']),
	calloutPosition: z.string().optional(),
	campaign: z.string(),
	ctaLinks: z.boolean(),
	ctaText: z.string(),
	ctaTextSecondary: z.string(),
	ctaUrl: z
		.string()
		.trim()
		.refine((value) => !/(?<=\S)\s+(?=\S)/g.test(value), {
			message: 'ctaUrl must not contain whitespace characters',
		}),
	ctaUrlSecondary: z
		.string()
		.trim()
		.refine((value) => !/(?<=\S)\s+(?=\S)/g.test(value), {
			message: 'ctaUrlSecondary must not contain whitespace characters',
		}),
	desktopImageAlt: z.string(),
	desktopImageCTAURL: z.string(),
	desktopImageURL: z
		.string()
		.trim()
		.refine((value) => !/(?<=\S)\s+(?=\S)/g.test(value), {
			message: 'desktopImageURL must not contain whitespace characters',
		}),
	experience: z.string(),
	header: z.string(),
	headerStyle: z.enum(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	heroStyle: z.enum(['HeroOverlay', 'HeroMini']),
	mobileImageAtl: z.string(),
	mobileImageCTAURL: z.string(),
	mobileImageURL: z
		.string()
		.trim()
		.refine((value) => !/(?<=\S)\s+(?=\S)/g.test(value), {
			message: 'mobileImageURL must not contain whitespace characters',
		}),
	snipeText: z.string(),
	subheader: z.string(),
	textStyle: z.enum(['Left', 'Center']),
	transparent: z.boolean(),
	userGroup: z.string(),
	width: z.number(),
})

export const McpProductSchema = z.object({
	id: z.string(),
	style: z.string(),
	color: z.string(),
	size: z.string(),
})

export const McpPdpRecsProductsSchema = z.array(McpProductSchema)

export const McpSitewideBannerSchema = z.object({
	id: z.string(),
	body: z.string(),
	campaign: z.string(),
	experience: z.string(),
	userGroup: z.string(),
	headerPromoTooltip: z.string(),
	headerPromoTooltipTitle: z.string(),
	promoMobileMessage: z.string(),
})

export type McpBaseSafe = z.infer<typeof McpBaseSchema>
export type McpPdpRecsProductsSafe = z.infer<typeof McpPdpRecsProductsSchema>
export type McpSitewideBannerSafe = z.infer<typeof McpSitewideBannerSchema>
