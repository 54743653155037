import type { CustomEventMap } from '~/types/global'
import { type McpCampaignTypes, McpTemplateNames, McpDataSets } from './mcp.constants'
import type {
	McpEvergageConfig,
	CampaignResponseTypeMap,
	McpProductRecsCampaignResponse,
	McpCampaignResponse,
	McpProduct,
	CampaignStat,
	McpBriefProductDetails,
} from '~/lib/types/mcp.interface'

/**
 * Enum representing Evergage events. Can also be accessed via window.Evergage.CustomEvents.
 * @enum {string}
 */
export enum EvergageEvent {
	OnEventResponse = 'evergage:onEventResponse',
	OnEventSend = 'evergage:onEventSend',
	OnStatSend = 'evergage:onStatSend',
	OnException = 'evergage:onException',
	OnTemplateDisplayEnd = 'evergage:onTemplateDisplayEnd',
	OnPageMatchStatusUpdated = 'evergage:onPageMatchStatusUpdated',
	OnInit = 'evergage:onInit',
	OnInitSitemap = 'evergage:onInitSitemap',
	OnShutDown = 'evergage:onShutDown',
	OnConsentRevoke = 'evergage:OnConsentRevoke',
}

/**
 * Checks if a specific function exists in the Evergage object on the window.
 *
 * @param functionToCheck - The name of the function to check for. If not provided, the function simply checks if the Evergage object exists on the window.
 * @returns A boolean indicating whether the specified function exists in the Evergage object on the window, or whether the Evergage object exists on the window if no function name is provided.
 */
export function evergageWindowFunctionExists(functionToCheck?: keyof typeof window.Evergage) {
	return (
		typeof window !== 'undefined' &&
		window.Evergage &&
		(functionToCheck === undefined ? true : typeof window.Evergage[functionToCheck] === 'function')
	)
}

/**
 * Retrieves the Evergage configuration from the evergage window object.
 *
 * @returns The Evergage configuration, or undefined if the Evergage window function 'getConfig' does not exist.
 */
export function getEvergageConfig(): McpEvergageConfig | undefined {
	if (!evergageWindowFunctionExists('getConfig')) {
		return undefined
	}

	return window.Evergage.getConfig?.() || undefined
}

/**
 * Retrieves all Evergage campaign responses from the evergage window object.
 *
 * @returns An array of campaign responses, or undefined if the Evergage window function 'getCampaignResponses' does not exist.
 */
export function getEvergageCampaignResponses(): McpCampaignResponse[] | undefined {
	if (!evergageWindowFunctionExists('getCampaignResponses')) {
		return undefined
	}

	return window.Evergage.getCampaignResponses?.() || undefined
}

/**
 * Searches the McpDataSets enum for a key given a potentially matching string value.
 *
 * @param value - The value to search for in the McpDataSets enum.
 * @returns The key in the McpDataSets enum that matches the given value, or undefined if no match is found.
 */
function findKeyInMcpDataSets(value: string): keyof typeof McpDataSets | undefined {
	const entry = Object.entries(McpDataSets).find(([_, val]) => val === value)
	return entry ? (entry[0] as keyof typeof McpDataSets) : undefined
}

/**
 * Retrieves the campaign data for any campaign types (HERO/PRODUCT_RECS). Searches by matching template name in MCP that is associated with that type of campaign (HERO/PRODUCT_RECS).
 *
 * @param campaignType - The campaign type (HERO/PRODUCT_RECS) to find.
 * @param campaigns - Updated campaigns in preview mode. This will take priority to the default published campaigns
 * @returns The campaign data and index within the campaign array, or undefined if the campaign could not be found, if the Evergage configuration could not be retrieved, or if the active data set could not be determined.
 */
export function getEvergageCampaignData(
	campaignType: McpCampaignTypes,
	campaigns?: McpCampaignResponse[],
): { campaign: McpCampaignResponse; indexOfCampaign: number } | undefined {
	const evergageConfig = getEvergageConfig()
	const activeDataSet = evergageConfig && findKeyInMcpDataSets(evergageConfig.dataset)
	const templateNameToFind = activeDataSet && McpTemplateNames[activeDataSet]?.[campaignType]
	const campaignsToSearch = campaigns?.length ? campaigns : getEvergageCampaignResponses() || []

	if (templateNameToFind) {
		const indexOfCampaign = campaignsToSearch.findIndex(
			(campaignResponse) => campaignResponse.templateNames?.[0] === templateNameToFind,
		)

		if (indexOfCampaign !== -1 && campaignsToSearch[indexOfCampaign].payload) {
			return { campaign: campaignsToSearch[indexOfCampaign], indexOfCampaign }
		}
	}

	return undefined
}

/**
 * Retrieves the index of a specific Evergage campaign in the list of campaign responses.
 *
 * @param campaignType - The campaign type (HERO/PRODUCT_RECS) to find.
 * @returns The index of the campaign in the list of campaign responses, or -1 if the campaign could not be found, if the Evergage configuration could not be retrieved, or if the active data set could not be determined.
 */
export function getEvergageCampaignIndex(campaignType: McpCampaignTypes, campaigns?: McpCampaignResponse[]): number {
	return getEvergageCampaignData(campaignType, campaigns)?.indexOfCampaign ?? -1
}
/**
 * Retrieves an Evergage campaign based on a given campaign ID.
 *
 * @param campaignType -  The campaign type (HERO or PRODUCT_RECT etc) to find.
 * @param campaigns - Updated campaigns in preview mode. This will take priority to the default published campaigns
 * @returns The campaign response for the given campaign ID, or undefined if the campaign could not be found or if there was an error retrieving the Evergage configuration or the active data set.
 */
export function getEvergageCampaign<T extends McpCampaignTypes>(
	campaignType: T,
	campaigns?: McpCampaignResponse[],
): CampaignResponseTypeMap<T> {
	return getEvergageCampaignData(campaignType, campaigns)?.campaign as CampaignResponseTypeMap<T>
}

/**
 * This function looks for the field specified in the MCP product and returns defaultValue if the field is nullish.
 *
 * @param product The MCP product
 * @param fieldName The name of the field to find in the product
 * @param defaultValue The default value to return if the field is not found
 */
export function getMcpProductFieldData<T>(product: McpProduct, fieldName: string, defaultValue: T): T {
	const directProperty = product[fieldName] // Accessing direct properties of the product
	const attributeValue = product.attributes?.[fieldName]?.value // Accessing attributes, if they exist
	const dimensionValue = product.dimensions?.[fieldName]?.[0] // Accessing dimensions, if they exist

	return directProperty ?? attributeValue ?? dimensionValue ?? defaultValue // If none of the above, return the default value
}

/**
 * Retrieves the brief MCP product details for a list of MCP products.
 *
 * The term "brief" here refers to the fact that we are only retrieving a subset of the product's properties
 * This function is in contrast to a full product detail retrieval, which would include all properties associated with a product.
 *
 * @param mcpProducts - An array of MCP products. Each MCP product is an object with various properties such as 'id', 'name', 'price', etc.
 * @see {@link McpProduct}
 *
 * The function maps over the array of MCP products and retrieves the specified properties for each MCP product.
 *
 * @returns An array of MCP product details. Each MCP product detail is an object containing only the specified properties.
 */
export function getBriefMcpProductDetails(
	mcpProducts: McpProductRecsCampaignResponse['payload']['products'],
): McpBriefProductDetails[] {
	return mcpProducts.map((product) => ({
		id: getMcpProductFieldData(product, 'id', undefined),
		style: getMcpProductFieldData(product, 'ProductId', undefined),
		color: getMcpProductFieldData(product, 'ColorId', undefined),
		size: getMcpProductFieldData(product, 'Size', undefined),
	}))
}

/**
 * Sends campaign statistics to the Evergage system.
 *
 * @param {CampaignStat} stat - The campaign statistics to be sent.
 * @returns {void}
 */
export function sendMcpEvents(stat: CampaignStat): void {
	if (evergageWindowFunctionExists('sendStat')) {
		window.Evergage.sendStat({ campaignStats: [stat] })
	}
}

/**
 * Adds the specified event handler to the specified Evergage custom event.
 *
 * @template K - The type of the event name, which should be a key of CustomEventMap.
 * @param {K} eventName - The name of the event to add the handler to.
 * @param {function} handler - The event handler function to add to the event. It receives an event of type CustomEventMap[K].
 * @returns {void}
 */
export function addEvergageCustomEventListener<K extends keyof CustomEventMap>(
	eventName: K,
	handler: (domEvent: CustomEventMap[K]) => void,
): void {
	document?.addEventListener(eventName, handler)
}

/**
 * Removes the specified event handler from the specified Evergage custom event.
 *
 * @template K - The type of the event name, which should be a key of CustomEventMap.
 * @param {K} eventName - The name of the event to remove the handler from.
 * @param {function} handler - The event handler function to remove from the event. It should be the same function that was added to the event.
 * @returns {void}
 */
export function removeEvergageCustomEventListener<K extends keyof CustomEventMap>(
	eventName: K,
	handler: (domEvent: CustomEventMap[K]) => void,
): void {
	document?.removeEventListener(eventName, handler)
}
