/* eslint-disable no-underscore-dangle */

import {
	isConstructorIOEnabled,
	isConstructorIOBrowseEnabled,
	getCioResults,
	getCioBreadcrumbs,
	fetchUACAPISearchResults,
	CIO_BROWSE_GROUP,
	SEARCH_REFINEMENT_URL,
	getLocalizedSizeMap,
} from '~/lib/search'
import { deepObjectArrayCompare } from '~/lib/utils'
import {
	getProductListByCategory,
	getProductSuggestions,
	getProductTilesByProductIds,
	PRODUCTS_PER_PAGE,
	isProductOutOfStock,
	type SearchProductsQuery,
} from '~/lib/products'
import { ensureNumber, ensureString } from '~/types/strict-null-helpers'
import type {
	Category,
	MasterProduct,
	SearchRefinementAttribute,
	SearchSortingOption,
} from '~/graphql/generated/uacapi/type-document-node'
import type { McpContextType } from '~/lib/types/mcp.interface'
import type {
	CioFetchParams,
	CioSearchEndpointParams,
	ClientProductList,
	ClientProductTile,
} from '~/lib/types/product.interface'

import type { BreadcrumbTrail } from '~/components/shared/Breadcrumbs'
import { createClientLogger } from '~/lib/logger'
import { DEFAULT_SORT_RULE } from '~/lib/constants'
import type { SizeMapOptionsType } from '~/lib/size-like-mine'
import type { UserWithSession } from '~/lib/client-only/auth/types'
import { standAloneApolloClient } from '~/lib/client-server/uacapi-client'
import { getFilterRefinementInput } from '~/lib/routes'
import { categoryTreeToBreadcrumbTrail, getBreadcrumbs } from '~/lib/plp-seo'
import { getProductTiles } from '~/lib/product-suggestions'
import { getBriefMcpProductDetails } from '~/lib/client-only/mcp/evergage'
import type { McpPdpRecsProductsSafe } from '~/lib/client-server/cms/sources/mcp/shared/schema'
import { PdpRecsImpl } from '~/lib/client-server/cms/sources/mcp/shared/pdp-recs'

const logger = createClientLogger('product-provider')

export enum ProductSource {
	'ConstructorIO' = 'ConstructorIO',
	'UACAPI' = 'UACAPI',
	'MCP' = 'MCP',
}

export interface ProductContextInterface {
	searchBrowserSource: ProductSource
	categoryBrowserSource: ProductSource
	breadcrumbs: BreadcrumbTrail
	productList: ClientProductList | null
	allRefinements: SearchRefinementAttribute[]
	sortOptions: SearchSortingOption[]
	sizeMap: SizeMapOptionsType | undefined
	updateBrowserResults: (category: Category, searchContext: SearchProductsQuery) => Promise<boolean>
	updateSearchResults: (searchContext: SearchProductsQuery) => Promise<boolean>
}

/**
 * Fetches detailed product information for a given list of MCP products from UACAPI.
 *
 * This function first extracts unique product styles from the MCP products.
 * It then fetches detailed product information from UACAPI using these styles.
 * Finally, it maps each MCP product to its corresponding detailed product information.
 *
 * @param mcpProducts - An array of MCP products for which detailed product information is to be fetched. Safe - this means that the function expects to receive MCP products data that has already been validated by Zod.
 * @param locale - The locale to be used when fetching product information from UACAPI.
 * @returns An array of detailed product information corresponding to the given MCP products.
 */
async function getDetailedProducts(mcpProducts: McpPdpRecsProductsSafe, locale: string) {
	// Extract product IDs from mcpProductRecommendations
	const uniqueStyles = new Set(mcpProducts?.map((product) => product.style))
	const mcpProductIds = Array.from(uniqueStyles, (style) => ({ style }))

	// Fetch products from UACAPI based on the product IDs from MCP
	const productsByProductIds = await getProductTilesByProductIds(standAloneApolloClient(locale), {
		productIds: mcpProductIds,
	})

	const detailedProducts: MasterProduct[] = mcpProducts
		.map((product) => {
			// Find the corresponding product in productsByProductIds
			const detailedProduct = productsByProductIds.data.productsById.find(
				(productId) => productId && 'style' in productId && productId.style === product.style,
			)
			return detailedProduct
		})
		.filter((product): product is MasterProduct => !!product)

	return detailedProducts
}

/**
 * The ProductProvider is a context provider that houses the logic for fetching
 * and updating product data for both Search and Browse requests.  It's important
 * that the provider does not use any state that is specific to a single page nor
 * that it make any assumptions about the current query parameters on the page during
 * initialization or update.  Instead, it should rely on the `options` prop to
 * determine the initial state and the `updateSearchResults` and `updateBrowserResults`
 * functions to update the state based on the current query parameters.
 *
 * @param param0
 * @returns
 */
export class ProductManager {
	_allRefinements: SearchRefinementAttribute[]
	_sortOptions: SearchSortingOption[]
	_locale: string
	_user?: UserWithSession

	constructor({
		allRefinements,
		sortOptions,
		locale,
		user,
	}: {
		allRefinements: SearchRefinementAttribute[]
		sortOptions: SearchSortingOption[]
		locale: string
		user?: UserWithSession
	}) {
		this._allRefinements = allRefinements
		this._locale = locale
		this._user = user
		this._sortOptions = sortOptions
	}

	public get categoryBrowserSource() {
		return isConstructorIOBrowseEnabled(this._locale) ? ProductSource.ConstructorIO : ProductSource.UACAPI
	}

	public get searchBrowserSource() {
		return isConstructorIOEnabled(this._locale) ? ProductSource.ConstructorIO : ProductSource.UACAPI
	}

	/**
	 * Fetches personalized product recommendations.
	 *
	 * This method attempts to get product recommendations from the personalization service.
	 * If the personalization is not loaded or the response is invalid, it returns undefined.
	 * If the response is valid, it converts the MCP products to master products and returns an object containing:
	 * - recommendations: An array of master products.
	 * - isLoading: A boolean indicating whether the product recommendations are still loading.
	 *
	 * @param personalization - The personalization context.
	 * @returns An object containing product recommendations and related information, or undefined if no response was received from the personalization service or if the personalization service is not loaded.
	 */
	public async getPersonalizationProductRecommendations(personalization?: McpContextType | undefined) {
		const isLoadedPersonalization = personalization?.evergageLoaded
		const personalizationCampaigns = personalization?.campaigns?.length

		// If the personalization service is not loaded or campaigns are unavailable due to fetching, failure to load, or no campaigns being available, return undefined
		if (!isLoadedPersonalization || !personalizationCampaigns) {
			return undefined
		}

		const personalizationResponse = personalization?.getProductRecs()

		// Check if the response is valid and contains product recommendations
		if (!personalizationResponse?.payload?.products?.length) {
			return undefined
		}

		// Extract brief product details from the personalization response
		const briefMcpProductDetails = getBriefMcpProductDetails(personalizationResponse.payload.products)
		// Parse the brief product details to check if the data is valid
		const parsedMcpProducts = new PdpRecsImpl(briefMcpProductDetails).raw

		if (!parsedMcpProducts) {
			return undefined
		}

		// NOTE! Since having a hover image and badges are blocker for launch,
		// we are making extra (expensive) call to UACAPI to fetch the detailed product information.
		// We will be using productIds from MCP and fetch the detailed product information using UACAPI to render the product’s tile.
		const detailedProducts = await getDetailedProducts(parsedMcpProducts, this._locale)
		const productTiles = getProductTiles(detailedProducts).suggestions
		const filterSoldOutProducts = productTiles.filter((productTile) => {
			return !isProductOutOfStock(productTile as ClientProductTile)
		})
		return {
			recommendations: filterSoldOutProducts ?? [],
			isLoading: !isLoadedPersonalization ?? false,
			source: ProductSource.MCP,
			context: {
				campaign: personalizationResponse.payload.campaign,
				experience: personalizationResponse.payload.experience,
				userGroup: personalizationResponse.payload.userGroup,
			},
		}
	}

	/**
	 * Fetches product recommendations from UACAPI based on a given product style.
	 *
	 * This method sends a request to UACAPI and awaits the response. It then constructs an object containing:
	 * - recommendations: An array of product recommendations, or an empty array if no recommendations were received.
	 * - isLoading: A boolean indicating whether the product recommendations are still loading.
	 *
	 * @param productStyle - The style of the product for which recommendations are to be fetched.
	 * @returns An object containing product recommendations and related personalized product recs
	 */
	public async getUacapiProductRecommendations(productStyle: string) {
		const response = await getProductSuggestions(standAloneApolloClient(this._locale), { productStyle })
		return {
			recommendations: getProductTiles(response?.data?.productRecommendations)?.suggestions ?? [],
			isLoading: response?.loading ?? false,
			source: ProductSource.UACAPI,
		}
	}

	/**
	 * Handler function to make requests to ConstructorIO for updated product data
	 */
	private async getCioProducts({
		fetchParams,
		requestContext,
		refinementUrl,
	}: {
		fetchParams: CioFetchParams
		requestContext: SearchProductsQuery
		refinementUrl?: string
	}) {
		return getCioResults({
			user: this._user,
			locale: this._locale,
			fetchParams,
			requestContext,
			allRefinements: this._allRefinements,
			sortOptions: this._sortOptions,
			refinementUrl,
		})
	}

	/**
	 * This is a fallback function used when ConstructorIO fails on Search requests.
	 */
	private async getUacapiProductsBySearchQuery({
		fetchParams,
		requestContext,
	}: {
		fetchParams: CioSearchEndpointParams
		requestContext: SearchProductsQuery
	}) {
		const { query: searchTerm } = fetchParams
		const { start = 0, srule: sortRule = DEFAULT_SORT_RULE } = requestContext
		const offset = ensureNumber(+start)

		return fetchUACAPISearchResults({
			locale: this._locale,
			requestContext,
			searchTerm,
			sortRule,
			offset,
			refinementUrl: '/search/',
		})
	}

	/**
	 * Helper function that houses the logic to fetch, clean, and update
	 * the product list and breadcrumbs for Search requests
	 */
	public async getProductsByKeyword(searchContext: SearchProductsQuery) {
		const updatedSearchQuery = searchContext.q

		if (updatedSearchQuery) {
			const requestParams = {
				fetchParams: {
					query: updatedSearchQuery,
				},
				requestContext: searchContext,
			}

			const fetchPromise =
				this.searchBrowserSource === ProductSource.ConstructorIO
					? this.getCioProducts(requestParams)
					: this.getUacapiProductsBySearchQuery(requestParams)

			return fetchPromise
				.catch((e) => {
					// If fetch fails when CIO is enabled, fall back to a UACAPI Search
					// Otherwise, redirect user to 500 page because it is a UACAPI failure
					const message = `Message: ${e}, Query: '${updatedSearchQuery}', Params: ${searchContext}`
					if (this.searchBrowserSource === ProductSource.ConstructorIO) {
						logger.error(`${message}, Source: 'ConstructorIO', Fallback: true`)
						return this.getUacapiProductsBySearchQuery(requestParams)
					}

					// NOTE: This will be caught in the second catch in this chain.
					throw new Error(`${message}, Source: 'UACAPI', Fallback: false`)
				})
				.then((products) => {
					const breadcrumbs = getCioBreadcrumbs({
						locale: this._locale,
						baseUrl: SEARCH_REFINEMENT_URL,
						query: updatedSearchQuery,
						refinementAttributes: products?.refinementAttributes,
					})

					return {
						products,
						breadcrumbs,
					}
				})
				.catch((err) => {
					logger.error(err)
					return undefined
				})
		}
		return Promise.resolve(undefined)
	}

	/**
	 * Helper function that houses the logic to fetch, clean, and update
	 * the product list and breadcrumbs for ConstructorIO Browse requests
	 */
	public async getProductsByCategory(
		category: Category,
		searchContext: SearchProductsQuery,
	): Promise<{ products: ClientProductList | null; breadcrumbs: BreadcrumbTrail }> {
		if (this.categoryBrowserSource === ProductSource.ConstructorIO && category) {
			return this.getCioProducts({
				requestContext: searchContext,
				fetchParams: {
					filterName: CIO_BROWSE_GROUP,
					filterValue: ensureString(category.id),
				},
				refinementUrl: ensureString(category.url),
			})
				.then((products) => {
					const breadcrumbs = getCioBreadcrumbs({
						locale: this._locale,
						baseUrl: ensureString(category.url),
						trail: products.breadcrumbTrail,
						refinementAttributes: products.refinementAttributes,
					})

					return {
						products,
						breadcrumbs,
					}
				})
				.catch((e) => {
					logger.error(
						`Message: ${e}, Category ID: ${category?.id}, Category: ${category}, Params: ${JSON.stringify(
							searchContext,
						)}, Source: ConstructorIO`,
					)
					return {
						products: null,
						breadcrumbs: [],
					}
				})
		}

		if (this.categoryBrowserSource === ProductSource.UACAPI && category) {
			// Get the refinements for the category
			const { refinements } = getFilterRefinementInput(
				this._allRefinements,
				category.url || '',
				searchContext,
				this._locale,
			)

			const searchRefinementInput = {
				category: category.id,
				customRefinements: refinements,
			}

			// Given the refinements and other inputted data, get the product list
			const productList = await getProductListByCategory(standAloneApolloClient(this._locale), {
				refinement: searchRefinementInput,
				amount: PRODUCTS_PER_PAGE,
				sortDirection: searchContext.srule,
				offset: +(searchContext.start || 0),
				query: searchContext.q,
			})

			// Now build the breadcrumb based on the category and the refinements associated with
			// the product list.
			const breadcrumbs = getBreadcrumbs(
				productList.refinementAttributes,
				category.url || '',
				categoryTreeToBreadcrumbTrail(category.parentCategoryTree),
				this._locale,
				category.id,
			)

			return {
				products: productList,
				breadcrumbs,
			}
		}

		return Promise.resolve({
			products: null,
			breadcrumbs: [],
		})
	}

	static getSizeMapFromRefinements(refinementAttributes: SearchRefinementAttribute[] | undefined) {
		return getLocalizedSizeMap(refinementAttributes)
	}
}

let _productManager: ProductManager

export function useProducts({
	locale,
	user,
	allRefinements,
	sortOptions,
}: {
	locale: string
	user?: UserWithSession
	allRefinements: SearchRefinementAttribute[]
	sortOptions: SearchSortingOption[]
}) {
	// Recreate the product manager if the locale, user, allRefinements, or sortOptions change
	if (
		!_productManager ||
		_productManager._locale !== locale ||
		_productManager._user !== user ||
		!deepObjectArrayCompare(_productManager._allRefinements, allRefinements) ||
		!deepObjectArrayCompare(_productManager._sortOptions, sortOptions)
	) {
		_productManager = new ProductManager({ locale, user, allRefinements, sortOptions })
	}

	return _productManager
}
