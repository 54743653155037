import { useContext } from 'react'
import { PersonalizationContext } from '~/components/providers/PersonalizationProvider/PersonalizationProvider'

/**
 * Provides access to the Personalization context.
 *
 * It retrieves the Personalization context using the `useContext` hook from React. If the Personalization context is not available (i.e., if `usePersonalization` is used outside of a `PersonalizationProvider`), it throws an error.
 *
 * @returns The Personalization context, which includes the `campaigns` state.
 */
export default function usePersonalization() {
	return useContext(PersonalizationContext)
}
